@charset "utf-8";


/** link */
a{
  color: #333;
  
  &:hover{
    opacity: .6;
  }
  
  &._arrow{
    position: relative;
    
    &:after{
      content:"";
      display: inline-block;
      margin-left: .3em;
      height: 6px;
      width: 6px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      transform: rotate(45deg);
    }
  }
  
  &._inline{
    position: relative;
    
    &._phone{
      text-align: center;
      padding-left: 1.25em;
      
      &:before{
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -.5em;
        content: "\e906";
        font-family: 'icomoon' !important;
        font-size: 1.0em;
        margin-right: .5em;
        line-height: 1;
      }
    }
  }
  
  &._blank,
  &._external{
    position:relative;
    display: inline-block;
    padding-right: 1.2em;
    
    &:after{
      content: "\e901";
      font-family: 'icomoon' !important;
      position: absolute;
      right: 0em;
      top: 50%;
      margin-top: -.5em;
      font-size: .75em;
      margin-left: .5em;
      line-height: 1;
      text-decoration: none;
    }
  }
  

  &._phone{
    line-height: 1;

    &:before{
      content: "\e909";
      font-family: 'icomoon' !important;
      display: inline-block;
      margin: 0 .3em 0 0;
      font-size: .86em;
    }
  }//._phone
  
  &._btn{
    position: relative;
    display: block;
    border: 2px solid #101010;
    text-align: left;
    padding: 1em 2em 1em .8em;
    color: #101010;
    border-radius: .3em;
    text-decoration: none;
    line-height: 1.0;
    vertical-align: bottom;
    max-width: 386px;
    box-sizing: border-box;

    &._arrow{
      position: relative;
      
      &:after{
        display: block;
        position: absolute;
        right:1em;
        top: 50%;
        bottom: 0;
        margin-top: -4px;
      }
    }
    
    &._dl{
      padding: 1em .8em 1em 3em;
      
      &:after{
        position: absolute;
        left: .5em;
        top: 50%;
        margin-top: -.5em;
        content: "\e9c7";
        font-family: 'icomoon' !important;
        font-size: 1.5em;
        line-height: 1;
        text-decoration: none;  
      }
    }
    
    &._blank,
    &._external{

      &:after{
        content: "\e901";
        font-family: 'icomoon' !important;
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -.5em;
        font-size: .75em;
        margin-left: .5em;
        line-height: 1;
        text-decoration: none;
      }
    }

    &._pdf{
      
      &:after{
        content: "\e903";
        font-family: 'icomoon' !important;
        position: absolute;
        color: #fff;
        border-radius: 50%;
        background: #444;
        right: 1em;
        top: 50%;
        font-size: 1em;
        margin-left: .5em;
        padding: .2em;
        line-height: 1;
        text-decoration: none;
        transform: translate(0, -50%);
      }
    }//._pdf
    
    &._phone{
      text-align: center;
      padding: 1em 2em 1em 2.6em;
      font-size: 1.4rem;
      
      &:before{
        position: absolute;
        top: 50%;
        left: .8em;
        margin-top: -.5em;
        content: "\e909";
        font-family: 'icomoon' !important;
        font-size: 1.2em;
        margin-right: .5em;
        line-height: 1;
      }
    }//._phone
    
    &._prev{
      position: relative;
      color: #333;
      
      &:before{
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 1em;
        margin-top: -4px;
        line-height: 1;
        line-height: 1;
        height: 6px;
        width: 6px;
        border-top: 1px solid #333;
        border-right: 1px solid #333;
        transform: rotate(-135deg);
      }
      
      &:hover,
      &._off{
        border-color: #d5d5d5;
        color: #d5d5d5;
        
        &:before{
          border-color: #d5d5d5;
        }
      }
    }
    
    &._next{
      position: relative;
      color: #333;
      
      &:after{
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -4px;
        line-height: 1;
        height: 6px;
        width: 6px;
        border-top: 1px solid #333;
        border-right: 1px solid #333;
        transform: rotate(45deg); 
      }
      
      &:hover,
      &._off{
        border-color: #d5d5d5;
        color: #d5d5d5;
        
        &:after{
          border-color: #d5d5d5;
        }
      }
    }//._next
    
    &._closed{
      position: relative;
      color: #333;
      text-align: center;
      
      &:before,
      &:after{
        background-color: #333;
        content: '';
        display: inline-block;
        height: 14px;
        position: absolute;
        width: 2px;
        border: none;
      }
      
      &:after{
        content:"";
        right: 1em;
        left:auto;
        top: 50%;
        margin-top: -7px;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      
      &:before{
        right: 1em;
        left:auto;
        top: 50%;
        margin-top: -7px;
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }//._closed

    &._center{
      margin: auto;
    }

    &._light{
      border-color: #f6f6f6;
      color: #f6f6f6;

      &._arrow{
        &:after{
          border-color: #f6f6f6;
        }
      }
    }//&._light
  }//&._btn
  
  
  
  &._white{
    border-width: 2px;
    border-color: #f5f5f5;
    color: #f5f5f5;
    
    &._arrow{
      color: #f5f5f5;
      border-color: #f5f5f5;
      
      &:after{
        border-color: #f5f5f5;
      }
    }
    
    &._btn{
      &:hover{
        background: #f5f5f5;
        color: #101010;
        
        &._arrow{
          &:after{
            border-color: #101010;
          }
        }
      }
    }
  }//._white
  
  
  &._off{
    opacity: .3;
    pointer-events: none;
  }
}//&._btn

._getAdobe{
  background: #eee;
  padding: 24px;
  display: flex;

  .context{
    margin-left: 24px;
  }
}//._getAdobe
@media screen and (max-width: 767px) {
  ._getAdobe{
    flex-direction: column;
    text-align: center;

    .context{
      margin: 1em auto 0;
      text-align: left;
    }
  }//._getAdobe
}/** @media max-width: 767px */
._notes{

  > li{
    padding-left: 1em;
    text-indent: -1em;

    &:before{
      content: "※";
    }
    &:nth-of-type(n+2){
      margin-top: .5em;
    }
  }
}//._notes


._tableWrap{

}//._tableWrap
@media screen and (max-width: 767px) {
  ._tableWrap,
  ._overflow{
    overflow: auto;
    overflow-x: scroll;
    width: 100%!important;
  }//._tableWrap, _overflow
}/** @media max-width: 767px */

/** */
._tblStyle01{
  width: 100%;
  th,
  td{
    font-weight: normal;
    border: solid 1px #ddd;
    padding: 1em;
  }

  thead{
    th,
    td{
      text-align: center;
      background: #eee;
    }
  }

  tbody{
    th,
    td{
      text-align: left;
    }

    td{
      &.price{
        text-align: right;

        .yen{
          font-size: .8em;
        }
      }
    }
  }

  .ex{
    display: inline-block;
    font-size: 1.3rem;
    font-feature-settings : "palt";
    
    &:before{
      content: "（";
    }
    &:after{
      content: "）";
    }
    
    span{
        
      &:after{
        content: "、";
      }
    }
  }//ex
}//._pricetable
@media screen and (max-width: 767px) {
  ._tblStyle01{
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    caption{
      display: block;
      text-align: center;
    }
    
    thead {
      display:none;
    }
    
    tbody,
    tbody tr{
      box-sizing: border-box;
      display: block;
      width: 100%;
    }
    
    tbody{
      tr{
        margin-top: 1.5em;
        border: solid 1px #ddd;
        
        &:first-of-type{
          margin-top: .5em;
        }
      }
      
      th {
        display:block;
        box-sizing: border-box;
        width: 100%;
        border: none;
        //margin-bottom: .5em;
        //font-size: 1.4rem;
        background: #ddd;
      }
      td {
        display:block;
        box-sizing: border-box;
        width: 100%;
        border: none;
        //font-size: 1.4rem;
        
        &:before {
          content: attr(label);
          float: left;
          clear:both;
        }

        &:nth-of-type(n+2){
          border-top: solid 1px #ddd;
        }

        
        p{
          padding-left: 10em;
        }
      }
    }
    .ex{
      display: block;
    }
    .price{
      font-size: 1.4rem;
    }
    .total{
      border-top: solid 1px #ccc;
    }
  }//._tblstyle01
}/** @media max-width: 767px */


/** */
._tblStyle02{
  position: relative;
  width: 100%;

  th,
  td{
    font-weight: normal;
    border: solid 1px #999;
    padding: 1em;
    white-space: nowrap;

    &._nowrap{
      
    }
  }

  thead{
    th,
    td{
      text-align: center;
      background: #666;
      color: #fff;
    }
  }

  tbody{
    th,
    td{
      text-align: left;
    }

    td{
      &.price{
        text-align: right;

        .yen{
          font-size: .8em;
        }
      }
    }
  }

  .ex{
    display: inline-block;
    font-size: 1.3rem;
    font-feature-settings : "palt";
    
    &:before{
      content: "（";
    }
    &:after{
      content: "）";
    }
    
    span{
        
      &:after{
        content: "、";
      }
    }
  }//ex
}//._tblStyle02
@media screen and (max-width: 767px) {
  ._tblStyle022{
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    caption{
      display: block;
      text-align: center;
    }
    
    thead {
      display:none;
    }
    
    tbody,
    tbody tr{
      box-sizing: border-box;
      display: block;
      width: 100%;
    }
    
    tbody{
      tr{
        margin-top: 1.5em;
        border: solid 1px #ddd;
        
        &:first-of-type{
          margin-top: .5em;
        }
      }
      
      th {
        display:block;
        box-sizing: border-box;
        width: 100%;
        border: none;
        //margin-bottom: .5em;
        //font-size: 1.4rem;
        background: #ddd;
      }
      td {
        display:block;
        box-sizing: border-box;
        width: 100%;
        border: none;
        //font-size: 1.4rem;
        
        &:before {
          content: attr(label);
          float: left;
          clear:both;
        }

        &:nth-of-type(n+2){
          border-top: solid 1px #ddd;
        }

        
        p{
          padding-left: 10em;
        }
      }
    }
    .ex{
      display: block;
    }
    .price{
      font-size: 1.4rem;
    }
    .total{
      border-top: solid 1px #ccc;
    }
  }//._tblstyle02
}/** @media max-width: 767px */


/*** 営業カレンダー */
.calendar{
  .caption {
    text-align: left;
    font-weight: normal;
    padding-bottom: 0.5em;
    font-size: 1.6rem;
    letter-spacing: .05em;
    
    strong{
      font-size: 124%;
    }
  }  
}//.calendar

._calendar {
  border-top: none;
  border-left: none;
  //width: 225px;
  
  + .notes{
    margin-top: .5em;
    
    &:before{
      content: "※";
    }
  }

  caption{
    text-align: left;
    font-weight: normal;
    padding-bottom: 0.5em;
    font-size: 1.6rem;
    letter-spacing: .05em;
    
    strong{
      font-size: 124%;
    }
  }    
  th,
  td {
    border-right: 1px solid #111111;
    border-bottom: 1px solid #111111;
    padding: .5em;
    text-align: center;
    line-height: 1.1;
    color:#eee;
    background:#202020;
  }
  thead {
    background: #677580;
    color: #eee;
    
    td{
      background: #333;
    }
    
    span {
      color: #FFF;
    }
  } 
  
  tbody {
    background: #fff;

    td{
      span {color: inherit;}
    }
  }

  .closed {
    background: #444;
    //span {color: #FFF;}
  }
  .notes{
    background: #ccc;
    color: #333;
  }
  
  .sun {color: $holiday; }
  .sat {color: #eee; }
  .holiday {color: $holiday; }
  .closed,
  .closedAll {
    background: #666;
    //span {color: #FFF; }
  }
  .notes{
    background: #600;
    color: #fff;
  }
  .cal_tit{
    width: 225px;
  }
}//._calendar

._calendar-legends {
  margin-top: 0.5em;
  padding: 0;
  list-style: none;
  font-size: 1.6rem;
  font-feature-settings : "palt";
  letter-spacing: .05em;
  
  .event {
    color: #FFD907;
  }
  .closed {
    color: #666;
  }
  .closedAll {
    color: #666;
  }
  .notes{
    color: #600;
  }
  
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}