@charset 'utf-8';

html,
body{
  width: 100%;
}

html {
  color: #fff;
  font-size: 62.5%;
  line-height: 1.3;
  background: #111;
  overflow-y: scroll;
}

body {
  font-family: "MazdaType", "Noto Sans CJK JP","Noto Sans JP", "Hiragino Kaku Gothic ProN", "HiraKakuProN-W3", "Meiryo", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}
h1,h2,h3,h4,h5,h6,dl,dt,dd,p,figure{
  margin: inherit;
  margin: 0;
  padding: 0;
  font-size: 100%;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 500;
  font-feature-settings : "palt";
  letter-spacing: .1em;
  vertical-align: middle;

  .en{
    font-size: 1.18em;
    vertical-align: baseline;
  }
}

ul,ol{
  padding: 0;
  margin: 0;
  list-style: none;
  
  li{
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

img{
  vertical-align: bottom;
}

._fz10{font-size: 1.0rem!important;}
._fz11{font-size: 1.1rem!important;}
._fz12{font-size: 1.2rem!important;}
._fz13{font-size: 1.3rem!important;}
._fz14{font-size: 1.4rem!important;}
._fz15{font-size: 1.5rem!important;}
._fz16{font-size: 1.6rem!important;}
._fz17{font-size: 1.7rem!important;}
._fz18{font-size: 1.8rem!important;}
._fz19{font-size: 1.9rem!important;}
._fz20{font-size: 2.0rem!important;}
._fz21{font-size: 2.1rem!important;}
._fz22{font-size: 2.2rem!important;}
._fz23{font-size: 2.3rem!important;}
._fz24{font-size: 2.4rem!important;}
._fz25{font-size: 2.5rem!important;}
._fz26{font-size: 2.6rem!important;}
._fz27{font-size: 2.7rem!important;}
._fz28{font-size: 2.8rem!important;}
._fz29{font-size: 2.9rem!important;}
._fz30{font-size: 3.0rem!important;}
._fz32{font-size: 3.2rem!important;}
._fz34{font-size: 3.4rem!important;}
._fz36{font-size: 3.6rem!important;}
._fz38{font-size: 3.8rem!important;}
._fz40{font-size: 4.0rem!important;}
._fz42{font-size: 4.2rem!important;}
._fz44{font-size: 4.4rem!important;}
._fz46{font-size: 4.6rem!important;}
._fz48{font-size: 4.8rem!important;}
._fz50{font-size: 5.0rem!important;}

._mt0, ._mv0{margin-top: 0px !important;}
._mt5, ._mv5{margin-top: 5px !important;}
._mt10, ._mv10{margin-top: 10px !important;}
._mt15, ._mv15{margin-top: 15px !important;}
._mt16, ._mv16{margin-top: 16px !important}
._mt20, ._mv20{margin-top: 20px !important;}
._mt24, ._mv24{margin-top: 24px !important}
._mt25, ._mv25{margin-top: 25px !important;}
._mt30, ._mv30{margin-top: 30px !important;}
._mt32, ._mv32{margin-top: 32px !important;}
._mt36, ._mv36{margin-top: 36px !important;}
._mt40, ._mv40{margin-top: 40px !important;}
._mt48, ._mv48{margin-top: 48px !important;}
._mt50, ._mv50{margin-top: 50px !important;}
._mt60, ._mv60{margin-top: 60px !important;}
._mt70, ._mv70{margin-top: 70px !important;}
._mt80, ._mv80{margin-top: 80px !important;}
._mt90, ._mv90{margin-top: 90px !important;}
._mt100, ._mv100{margin-top: 100px !important;}
._mb0, ._mv0{margin-bottom: 0px !important;}
._mb5, ._mv5{margin-bottom: 5px !important;}
._mb10, ._mv10{margin-bottom: 10px !important;}
._mb15, ._mv15{margin-bottom: 15px !important;}
._mb16, ._mv16{margin-bottom: 16px !important}
._mb20, ._mv20{margin-bottom: 20px !important;}
._mb24, ._mv24{margin-bottom: 24px !important}
._mb25, ._mv25{margin-bottom: 25px !important;}
._mb30, ._mv30{margin-bottom: 30px !important;}
._mb32, ._mv32{margin-bottom: 32px !important;}
._mb36, ._mv36{margin-bottom: 36px !important;}
._mb40, ._mv40{margin-bottom: 40px !important;}
._mb48, ._mv48{margin-bottom: 48px !important;}
._mb50, ._mv50{margin-bottom: 50px !important;}
._mb60, ._mv60{margin-bottom: 60px !important;}
._mb70, ._mv70{margin-bottom: 70px !important;}
._mb80, ._mv80{margin-bottom: 80px !important;}
._mb90, ._mv90{margin-bottom: 90px !important;}
._mb100, ._mv100{margin-bottom: 100px !important;}
._pt0, ._pv0{padding-top: 0px !important;}
._pt5, ._pv5{padding-top: 5px !important;}
._pt10, ._pv10{padding-top: 10px !important;}
._pt12, ._pv12{padding-top: 12px !important;}
._pt15, ._pv15{padding-top: 15px !important;}
._pt16, ._pv16{padding-top: 16px !important;}
._pt20, ._pv20{padding-top: 20px !important;}
._pt24, ._pv24{padding-top: 24px !important;}
._pt25, ._pv25{padding-top: 25px !important;}
._pt30, ._pv30{padding-top: 30px !important;}
._pt32, ._pv32{padding-top: 32px !important;}
._pt36, ._pv36{padding-top: 36px !important;}
._pt40, ._pv40{padding-top: 40px !important;}
._pt48, ._pv48{padding-top: 48px !important;}
._pt50, ._pv50{padding-top: 50px !important;}
._pt60, ._pv60{padding-top: 60px !important;}
._pt70, ._pv70{padding-top: 70px !important;}
._pt80, ._pv80{padding-top: 80px !important;}
._pt90, ._pv90{padding-top: 90px !important;}
._pt100, ._pv100{padding-top: 100px !important;}
._pb0, ._pv0{padding-bottom: 0px !important;}
._pb5, ._pv5{padding-bottom: 5px !important;}
._pb10, ._pv10{padding-bottom: 10px !important;}
._pb12, ._pv12{padding-bottom: 12px !important;}
._pb15, ._pv15{padding-bottom: 15px !important;}
._pb16, ._pv16{padding-bottom: 16px !important;}
._pb20, ._pv20{padding-bottom: 20px !important;}
._pb24, ._pv24{padding-bottom: 24px !important;}
._pb25, ._pv25{padding-bottom: 25px !important;}
._pb30, ._pv30{padding-bottom: 30px !important;}
._pb32, ._pv32{padding-bottom: 32px !important;}
._pb36, ._pv36{padding-bottom: 36px !important;}
._pb40, ._pv40{padding-bottom: 40px !important;}
._pb48, ._pv48{padding-bottom: 48px !important;}
._pb50, ._pv50{padding-bottom: 50px !important;}
._pb60, ._pv60{padding-bottom: 60px !important;}
._pb70, ._pv70{padding-bottom: 70px !important;}
._pb80, ._pv80{padding-bottom: 80px !important;}
._pb90, ._pv90{padding-bottom: 90px !important;}
._pb100, ._pv100{padding-bottom: 100px !important;}

._none{
  display: none;
}
._tume{
  font-feature-settings : "palt";
}

._left{text-align: left;}
._right{text-align: right;}
._center{text-align: center;}

._bold{
  font-weight: bold;
}
._normal{
  font-weight: normal;
}



*{
  box-sizing: border-box;
}

img{
  max-width: 100%;
}


@media screen and (max-width: 767px){
  .isMobile {
    display: none;
    width: 1px;
  }
}

@media screen and (min-width: 768px) {
  .isDesktop {
    display: none;
    width: 2px;
  }
}

.pc-item{
  display: block !important;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.sp-item{
  display: none !important;
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

._cols2,
._cols3,
._cols4,
._cols6{
  display: flex;
  flex-wrap: wrap;

  ._col{
    margin-left: 24px;
  }
}

._cols2{
  ._col{
    width: calc(100% / 2 - 12px);
    
    &:nth-of-type(2n+1){
      margin-left: 0;
    }
    &:nth-of-type(n+3){
      margin-top: 24px;
    }
  }
}
._cols3{
  ._col{
    width: calc(100% / 3 - 16px);

    &:nth-of-type(3n+1){
      margin-left: 0;
    }
    &:nth-of-type(n+4){
      margin-top: 24px;
    }
  }
}
._cols4{
  ._col{
    width: calc(100% / 4 - 18px);
    
    &:nth-of-type(4n+1){
      margin-left: 0;
    }
    &:nth-of-type(n+5){
      margin-top: 24px;
    }
    
  }
}
._cols6{
  ._col{
    width: calc(100% / 6 - 20px);

    &:nth-of-type(6n+1){
      margin-left: 0;
    }
    &:nth-of-type(n+7){
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 767px) {
  ._cols2,
  ._cols3,
  ._cols4
  ._cols6{
    flex-direction: column;

    ._col{
      width: 100%;
      margin-left: 0;

      &:nth-of-type(n+2){
        margin-top: 24px;
      }
    }
  }
  ._cols2{
  }
  ._cols3{ }
  ._cols4{ }
  ._cols6{ }
  
}/** @media max-width: 767px */


