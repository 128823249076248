@charset 'utf-8';

.header{
  background: #111;
  color: #fff;

  ._inner{
    position: relative;
    width: 100%;
    max-width: 1000px;
    min-height: 100px;
    padding-left: 120px;
    padding-right: 20px;
    margin: auto;

  }//._inner

  .mazda-logo{
    display: block;
    width: 108x;
    padding: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    img{
      width: 100%;
    }
  }//.mazda-logo

  &-logo{
    padding-top: 20px;
    
    .jp{
      font-weight: bold;
      font-size: 2.8rem;
      letter-spacing: .05em;
    }
    .en{
      display: inline-block;
      margin-left: 1em;
      font-size: 2.1rem;
      letter-spacing: .15em;
    }
  }

  @media screen and (max-width: 960px) {
    &-logo{
      .en{
        display: none;
      }
    }
  }/** @media max-width: 767px */
  &-nav{
    position: relative;
    width: 100%;

    &-list{
      display: flex;
      justify-content: space-between;

      &-item{
        font-size: 1.6rem;
        a{
          position: relative;
          display: block;
          color: #fff;
          text-decoration: none;
          padding: 5px 0 14px 0;
          
          &::after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 2px;
            background: #fff;
            width: 0;
            transition: all .3s ease-in-out;
          }

          &:hover{
            opacity: .6;
            &:after{
              width: 100%;
            }
          }
        }
      }//&-item
    }//&-list
  }//&-nav


  &-subnav{

    &-list{
      display: flex;
      position: absolute;
      right: 0;
      top: calc(-1.5em - 20px);

      &-item{
        font-size: 1.4rem;
        margin-left: 30px;

        a{
          position: relative;
          display: block;
          color: #fff;
          text-decoration: none;
          padding: 10px 0 10px 0;
          transition: all .3s ease-in-out;

          &:hover{
            opacity: .6;
          }

          &._login{
            padding-left: 1.3em;
            &:before{
              content: '';
              display: block;
              width: 1em;
              height: 1em;
              background: transparent url('../i/icn-login.png') no-repeat center center/contain;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(0, -50%);

            }//&:before
          }//&._login

          &._external{
            padding-right: 1em;
            &:after{
              content: "\e901";
              font-family: 'icomoon' !important;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -.5em;
              font-size: .75em;
              margin-left: .5em;
              line-height: 1;
              text-decoration: none;
            }
          }//&._external
        }//a
      }
    }//&-list
  }//&-subnav

}//.header

@media screen and (max-width: 767px) {
  .header{

    ._inner{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0px;
      min-height: 80px;
    }
    .mazda-logo{
      width: 72px;
      padding: 12px;
    }//.mazda-logo

    &-logo{
      padding-top: 0px;
      margin: 0 72px;
      text-align: center;
      span{
        display: block;
      }
      .jp{
        font-size: 2.1rem;
      }
      .en{
        font-size: 1.4rem;
        margin-left: 0;
      }
    }
    &-nav{
      display: none;
    }
  }//.header
}/** @media max-width: 767px */