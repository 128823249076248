@charset 'utf-8';

#sp_nav{
  .drawer-nav{
    padding: 1em;
    
    .drawer-menu{
      padding-bottom: 15vw;

      li{
        border-bottom: dotted 1px #666;
        a{
          position: relative;
          display: block;
          padding: 1.5em 2em 1.5em 2em;
          //background: #666;
          color: #fff;

          &::after{
            border-color: #fff;
          }

          &._login{
            position: relative;
            padding-left: 3.3em;
            &:before{
              content: "";
              display: block;
              width: 1em;
              height: 1em;
              background: transparent url('../i/icn-login.png') no-repeat left center/contain;
              position: absolute;
              left: 2em;
              top: 50%;
              transform:translate(0, -50%);
            }
          }//&._login
        }//a 
      }
    }
    
    .btn-wrap{
      padding: 1.5em 0;
      
      a._close{
        text-align: center;
      }
    }
  }//.drawer-menu
}