@charset 'utf-8';

.pagewrap{
  overflow: hidden;
}


main{
  //min-height: 50vh;
  background: #f6f6f6;
  //background: #fff;
  color: #333;

  section{
    padding: 36px 0;

    >._inner{
      position: relative;
      width: 100%;
      max-width: 960px;
      margin: auto;

      &:nth-of-type(n+2){
        margin-top: 36px;
      }
    }//._inner

    &.hero{
      padding: 0;
      
      >._inner{
        padding-top: 0;
        max-width: 1200px;
      }
    }
  }//section
}//main

@media screen and (max-width: 767px) {
  main{
    section{
      >._inner{
        padding-left: 16px;
        padding-right: 16px;
      }//._inner

      &.hero{
        >._inner{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }//main
}/** @media max-width: 767px */

@media screen and (min-width: 1201px) {
  main{
    section{
      &.hero{
        ._inner{
          padding-top: 24px;
        }
      }
    }
  }
}