@charset 'utf-8';

.bottom-nav{
  background: #ddd;

  ._inner{
    width: 100%;
    max-width: 960px;
    margin: auto;
    padding: 36px 0;
  }

  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    &-item{
      
      &:nth-of-type(n+2){
        margin-left: 16px;
      }
      
      a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 144px;
        height: 104px;
        border: solid 2px #111;
        border-radius: 4px;
        text-decoration: none;
        color: #333;
        font-weight: 500;
        
        &:before{
          content: "";
          display: block;
          width: 75px;
          height: 44px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-bottom: 12px;
        }

        &.nav-insurance{
          &:before{
            background-image: url('../i/icn-insurance.svg');
          }
        }
        &.nav-store{
          &:before{
            background-image: url('../i/icn-store.svg');
          }
        }
        &.nav-corporate{
          &:before{
            background-image: url('../i/icn-corporate.svg');
          }
        }
        &.nav-calendar{
          &:before{
            background-image: url('../i/icn-calendar.svg');
          }
        }
        &.nav-recruit{
          &:before{
            background-image: url('../i/icn-recruit.svg');
          }
        }
      }
    }
  }
}//.bottom-nav

.footer{
  background: #111;

  a{
    color: #fff;
    text-decoration: none;

    &:hover{
      opacity: .6;
    }
  }

  ._inner{
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 36px 20px;

    &:nth-of-type(n+2){
      padding-top: 0;
    }
  }

  &-leftCol{
    //width: 100%;
    width: calc(100% - 304px - 36px)!important;
  }

  &-rightCol{
    width: 304px!important;
    flex-shrink: 0;
    margin-left: 36px!important;
  }

  &-calendar{
    h3{
      display: none;
      font-size: 1.6rem;
    }

    .footer-calendars{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      >li{
        width: calc(100%/2 - 12px);
      }
    }//.footer-calendars
    
    ._calendar{
      width: 100%;
    }
  }//&-calendar

  @media screen and (min-width: 768px) and (max-width: 1020px) {
    &-calendar{
      .footer-calendars{
        flex-direction: column;

        >li{
          width: 100%;
          margin-top: 24px;

          &:first-of-type{
            margin-top: 0;
          }
        }
      }//.footer-calendars
    }//&-&-calendar
  }/** @media max-width: 767px */

  &-corporate{
    &-contact{
      dt{
        font-size: 1.8rem; 
      }
      dd{
        margin-top: .5em;

        span{
          display: inline-block;

          &:nth-of-type(n+2){
            &:before{
              content: "／";
              margin-left: .5em;
              margin-right: .5em;
            }
          }
        }
      }
    }
  }//&-corporate

  &-atrc{
    margin-top: 24px;
    background: #333;
    border-radius: 4px;
    padding: 16px;

    dl{
      dt{
        text-align: center;
        font-size: 1.8rem;
      }
      dd{
        text-align: center;
      }
    }
    ._phone{
      font-size: 2.4rem;
    }

    ._btnWrap{
      margin-top: 1em;
    }

  }//&-atrc

  &-hours{
    margin-top: 24px;

    dl{
      font-size: 1.6rem;
      dt{
        display: inline-block;
        width: 6em;
        &:before{
          content: "［";
        }
        &:after{
          content: "］";
        }
      }
      dd{
        display: inline-block;

        span{
          &:nth-of-type(n+2){
            &:before{
              content: "／";
              margin-left: .5em;
              margin-right: .5em;
            }
          }
        }
      }
    }
  }//&-hours

  &-nav{
    margin-top: 24px;
    padding-top: 24px;
    border-top: solid 1px #333;

    &-list{
      display: flex;
      justify-content: center;
      
      &-item{
        font-size: 1.4rem;

        &:nth-of-type(n+2){
          &:before{
            content: "｜";
            padding-left: .5em;
            padding-right: .5em;
          }
        }
      }
    }
  }//&-nav

  &-copyright{
    margin-top: 24px;
    p{
      text-align: center;
    }
  }//&-copyright
}//.footer

@media screen and (max-width: 767px) {
  .bottom-nav{
    ._inner{
      padding: 32px 16px;
    }
    &-list{
      justify-content: flex-start;

      &-item{
        width: calc(100% / 2 - 8px);
        margin-left: 16px;

        &:nth-of-type(2n+1){
          margin-left: 0px;
        }
        &:nth-of-type(n+3){
          margin-top: 16px;
        }
        
        a{
          width: 100%;
        }
      }
    }
  }//.bottom-nav

  .footer{
    overflow: hidden;

    ._inner{
      padding: 32px 16px;
    }

    &-leftCol,
    &-rightCol{
      width: 100%!important;
      max-width: 100%;
      margin-left: 0!important;
    }

    &-calendar{
      h3{
        display: block;
        text-align: center;
        margin-bottom: 12px;
      }
      .footer-calendars{
        flex-direction: column;
        
        >li{
          width: 100%;

          &:nth-of-type(n+2){
            margin-top: 24px;
          }
        }
      }//&-list
    }//&-calendar

    &-corporate{
      padding-top: 24px;
      border-top: solid 1px #333;
    }//&-corporate

    &-hours{
      dl{
        display: flex;
        flex-direction: column;

        &:nth-of-type(n+2){
          margin-top: 1em;
        }
        dt{
          width: 100%;
          text-align: center;
        }
        dd{
          width: 100%;
          margin-left: 1em;
          margin-top: 1em;
          span{
            display: block;
            &:nth-of-type(n+2){
              &:before{
                display: none;
              }
            }
          }
        }
      }
    }//&-hours

    &-nav{
      display: none;
    }//&-nav

    &-copyright{
      //padding-top: 24px;
      //border-top: solid 1px #333;
    }
  }//.footer
}/** @media max-width: 767px */