@charset 'utf-8';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

/** mazda font */
@font-face{
  font-family: MazdaType;
  src:url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Regular.woff2") format("woff2"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Regular.woff") format("woff"),
    url("https://www2.mazda.co.jp/common/assets/fonts/MazdaTypeTT-Regular.ttf") format("truetype"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-regular.eot") format('eot');
    font-weight: 300;
    font-style: normal;
}

@font-face{
  font-family: MazdaType;
  src:url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Medium.woff2") format("woff2"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Medium.woff") format("woff"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Medium.ttf") format("truetype"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Medium.eot") format('eot');
    font-weight: 500;
    font-style: normal;
}

@font-face{
  font-family: MazdaType;
  src:url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Bold.woff2") format("woff2"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Bold.woff") format("woff"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Bold.ttf") format("truetype"),
    url("https://www2.mazda.co.jp/common/fonts/MazdaTypeTT-Bold.eot") format('eot');
    font-weight: 700;
    font-style: normal;
}

/** icomoon */
@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?b2nig1');
  src:  url('../font/icomoon.eot?b2nig1#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?b2nig1') format('truetype'),
    url('../font/icomoon.woff?b2nig1') format('woff'),
    url('../font/icomoon.svg?b2nig1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icn-phone:before {
  content: "\e909";
}
.icon-icn-pdf2:before {
  content: "\e903";
}
.icon-icn-pdf3:before {
  content: "\e904";
}
.icon-icn-pdf4 .path1:before {
  content: "\e905";
  color: rgb(0, 0, 0);
}
.icon-icn-pdf4 .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icn-pdf4 .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icn-pdf4 .path4:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icn-pdf:before {
  content: "\e900";
}
.icon-icn-external:before {
  content: "\e901";
}
.icon-icn-arrow:before {
  content: "\e902";
}
